import { css, Image, Link, notDesktop, styled, Text } from '@obvio/app'
import { SvgBackground, SvgBigLogo, SvgMapArrow } from '@obvio/svg'
import { FadeIn, Map, MapSection, RichText } from '@obvio/template'
import { Button, Grid, Section, Stack, Video } from '@obvio/ui'

import { Accordion } from '../Accordion'
import { EventsCarousel } from '../EventsCarousel'
import { ImagesCarousel } from '../ImagesCarousel'
import { StrainsAccordion } from '../StrainsAccordion'
import { WineIndex } from '../WineIndex'
import { WineList } from '../WineList'
import { MAP_MARKERS, MAP_STYLE } from '@/constants/map'

import type { ReactElement } from 'react'

const SingleImage = styled(Image)`
  height: 47.5rem;
`

const RichTextWrap = styled.div`
  width: 80%;
  @media ${notDesktop} {
    width: 100%;
  }
`

const Image11Wrap = styled(Grid)`
  height: 36rem;
  @media ${notDesktop} {
    grid-template-columns: 1fr;
  }
`

const FullSection = styled(Section)`
  width: 100%;
  max-width: 100% !important;
  padding: 0;
`

const MapSectionSpacing = styled(MapSection)`
  margin: 0;
  > div {
    height: 100%;
  }
  @media ${notDesktop} {
    height: 25rem;
  }
`

const LogoSection = styled(Section)`
  padding-bottom: 0 !important;
  display: flex;
  justify-content: center;
  svg {
    width: 100% !important;
  }
`

const GridMedia = styled(Grid)`
  @media ${notDesktop} {
    grid-template-columns: 1fr;
  }
`

const MobileImageWrap = styled.div<{ $ratio?: number }>`
  ${(_, { $ratio }) =>
    $ratio
      ? css`
          width: 60%;
          aspect-ratio: ${$ratio};
        `
      : css`
          height: 100%;
        `};
  @media ${notDesktop} {
    min-height: 25rem;
    width: 100%;
  }
`

const SectionMedio = styled(Section)`
  @media ${notDesktop} {
    width: 100%;
    max-width: 100% !important;
    padding: 0;
  }
`

const Marker = styled.div<{ $active: boolean }>`
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 99999px;
  background: ${(_, { $active }) =>
    $active ? '#665F2F' : 'rgba(104, 98, 53, 0.36)'};
  z-index: ${(_, { $active }) => ($active ? 3 : 2)};
  position: relative;
  cursor: pointer;
`

const MarkerBody = styled.div`
  padding: ${(theme) => theme.spacing.small};
  background: ${(theme) => theme.colors.additional[2]};
  position: absolute;
  top: 200%;
  width: 15rem;
  a {
    text-decoration: underline;
    font-size: 1rem;
  }
`

const Destination = styled.div`
  position: absolute;
  top: ${(theme) => theme.spacing.large};
  left: ${(theme) => theme.spacing.large};
  font-family: ${(theme) => theme.secondaryFont};
  color: #665f2f;
  @media ${notDesktop} {
    display: none;
  }
`

const DestinationBottom = styled(Destination)`
  svg {
    transform: rotate(180deg);
  }
  top: unset;
  left: unset;
  right: ${(theme) => theme.spacing.large};
  bottom: ${(theme) => theme.spacing.large};
`

const VideoSection = styled(Section)`
  @media ${notDesktop} {
    width: 100%;
    max-width: 100% !important;
  }
`

export const customRenderers: (
  pageId?: string,
) => Record<string, (props: any) => ReactElement | null> = (_pageId) => ({
  logo: () => (
    <LogoSection>
      <FadeIn>
        <SvgBigLogo />
      </FadeIn>
    </LogoSection>
  ),
  image: ({ img }) => (
    <SectionMedio>
      <FadeIn>
        <SingleImage img={img[0]} />
      </FadeIn>
    </SectionMedio>
  ),
  richText: ({ richText }) => (
    <Section>
      <FadeIn>
        <RichTextWrap>
          <RichText value={richText} asMap={{ h3: 'h1' }} />
        </RichTextWrap>
      </FadeIn>
    </Section>
  ),
  'image-richText-cta': ({ image, description, text, url }) => (
    <Section
      id={
        description.toLowerCase().includes('siedlisko')
          ? 'terroir'
          : text?.toLowerCase() === 'poziom 511'
            ? 'atrakcje'
            : undefined
      }
    >
      <FadeIn>
        <GridMedia templateColumns="1fr 1.25fr" gap="extraLarge">
          <div>
            {image[0] ? (
              <MobileImageWrap $ratio={image[0]?.ratio}>
                <Image img={image[0]} />
              </MobileImageWrap>
            ) : null}
          </div>
          <Stack kind="vertical">
            <div>
              <RichText value={description} />
            </div>
            {url ? (
              <div>
                <Button href={url}>{text ?? 'SPRAWDŹ'}</Button>
              </div>
            ) : null}
          </Stack>
        </GridMedia>
      </FadeIn>
    </Section>
  ),
  'richText-cta-image': ({ description, text, url, image }) => (
    <Section
      id={
        description.toLowerCase().includes('proces produkcyjny')
          ? 'winifikacja'
          : undefined
      }
    >
      <FadeIn>
        <GridMedia templateColumns="1.25fr 1fr" gap="extraLarge">
          <Stack kind="vertical">
            <div>
              <RichText value={description} asMap={{ h3: 'h1' }} />
            </div>
            {url ? (
              <div>
                <Button href={url}>{text ?? 'SPRAWDŹ'}</Button>
              </div>
            ) : null}
          </Stack>
          <div>
            {image[0] ? (
              <MobileImageWrap $ratio={image[0]?.ratio}>
                <Image img={image[0]} />
              </MobileImageWrap>
            ) : null}
          </div>
        </GridMedia>
      </FadeIn>
    </Section>
  ),
  'image-1-1': ({ img1, img2 }) => (
    <Section>
      <FadeIn>
        <Image11Wrap templateColumns="1fr 2fr" gap="medium">
          <Image img={img1[0]} />
          <Image img={img2[0]} />
        </Image11Wrap>
      </FadeIn>
    </Section>
  ),
  background: () => (
    <FullSection>
      <FadeIn>
        <SvgBackground />
      </FadeIn>
    </FullSection>
  ),
  'wine-strains-list': () => (
    <FullSection>
      <FadeIn>
        <WineList />
      </FadeIn>
    </FullSection>
  ),
  'strains-accordion-image': ({ image }) => (
    <Section>
      <FadeIn>
        <StrainsAccordion image={image[0]} />
      </FadeIn>
    </Section>
  ),
  'wine-index': () => <WineIndex />,
  'image-carousel': ({ imgs }) => <ImagesCarousel images={imgs} />,
  map: () => (
    <MapSectionSpacing>
      <FadeIn>
        <Map
          defaultZoom={14}
          defaultCenter={{ lat: 50.45, lng: 19.55 }}
          apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API ?? ''}
          markers={MAP_MARKERS}
          marker={(data) => (
            <Marker
              onClick={() => data.onClick?.()}
              $active={Boolean(data.active)}
            >
              {data.active ? (
                <MarkerBody>
                  <Stack kind="vertical" spacing="extraSmall">
                    <Text tag="span" as="sub-h3">
                      {data.activeMarker?.title}
                    </Text>
                    <Link href={data.activeMarker?.description}>
                      Google Maps
                    </Link>
                  </Stack>
                </MarkerBody>
              ) : null}
            </Marker>
          )}
          options={{
            styles: MAP_STYLE,
            disableDefaultUI: true,
            restriction: {
              latLngBounds: {
                north: 50.733,
                south: 50.233,
                west: 19.233,
                east: 19.833,
              },
            },
          }}
        />
        <Destination>
          <Stack>
            <SvgMapArrow />
            <Text tag="span">ZAWIERCIE - 10 KM</Text>
          </Stack>
        </Destination>
        <DestinationBottom>
          <Stack>
            <Text tag="span">KRAKÓW - 56 KM</Text>
            <SvgMapArrow />
          </Stack>
        </DestinationBottom>
      </FadeIn>
    </MapSectionSpacing>
  ),
  'events-carousel': () => (
    <Section id="aktualnosci">
      <FadeIn>
        <EventsCarousel />
      </FadeIn>
    </Section>
  ),
  'richTextCta-accordion-description': ({
    description,
    text,
    url,
    accordion,
  }) => (
    <Section id="sprzedaz">
      <FadeIn>
        <GridMedia templateColumns="1fr 1fr" gap="extraLarge">
          <Stack kind="vertical" spacing="medium">
            <div>
              <RichText value={description} asMap={{ h3: 'h1' }} />
            </div>
            {url ? (
              <div>
                <Button href={url}>{text ?? 'SPRAWDŹ'}</Button>
              </div>
            ) : null}
          </Stack>
          <div>
            <Accordion>
              {accordion.map((item: any) => (
                <Accordion.Element
                  title={
                    <Text tag="span" as="sub-h3">
                      {item.title}
                    </Text>
                  }
                  key={item.title}
                >
                  <RichText value={item.data.text} />
                </Accordion.Element>
              ))}
            </Accordion>
          </div>
        </GridMedia>
      </FadeIn>
    </Section>
  ),
  vimeo: ({ id }) => (
    <VideoSection>
      <Video source="vimeo" id={id} />
    </VideoSection>
  ),
  youtube: ({ id }) => (
    <VideoSection>
      <Video source="youtube" id={id} />
    </VideoSection>
  ),
})
